<template>
    <div>
        
    </div>
</template>
<script>
export default {
    data(){
        return{
            
        }
    }
}
</script>
<style scoped lang='scss'>

</style>